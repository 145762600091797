.flex {
    display: flex;
}

.flex.column {
    flex-direction: column;
}

.flex.column-reverse {
    flex-direction: column-reverse;
}

.flex.row {
    flex-direction: row;
}

.flex.row-reverse {
    flex-direction: row-reverse;
}

.flex.full-height {
    height: 100%;
}