html, body {
  background-color: #F8F8FC;
  font-size: 16px;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.hidden-descendant {
  visibility: hidden;
}

.hides-descendants:hover .hidden-descendant {
  visibility: inherit;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.field-label + .field-content {
  margin-top: 8px;
}

.field-label + .helper-text + .field-content {
  margin-top: 0;
}

span, b, p {
  font-size: 1rem;
}